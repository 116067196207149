.animate-in-customer {
  animation-name: slideIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.animate-in-consultant {
  animation-name: slideIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-delay: 6s;
  animation-fill-mode: forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(50vw);
  }
  to {
    opacity: 1;
    transform: translate(0);
  }
}

.pulseAni {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  animation-duration: 2.5s;
  animation-delay: 12s;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.125);
  }
  80% {
    transform: scale(1);
  }
}

.animated-first-dot {
  animation-name: firstPointAni;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.animated-second-dot {
  animation-name: secondPointAni;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.animated-third-dot {
  animation-name: thirdPointAni;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes firstPointAni {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes secondPointAni {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  55% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes thirdPointAni {
  0% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*iteration Count*/
.loading-counter {
  color: white;
  font-family: "Source Sans Pro", sans-serif !important;
}

.loading-counter::before {
  content: counter(count);
  animation: counter 3s linear;
  animation-fill-mode: forwards;
}

@keyframes counter {
  0% {
    counter-increment: count 0;
  }
  10% {
    counter-increment: count 12;
  }
  20% {
    counter-increment: count 25;
  }
  30% {
    counter-increment: count 31;
  }
  40% {
    counter-increment: count 43;
  }
  50% {
    counter-increment: count 59;
  }
  60% {
    counter-increment: count 67;
  }
  70% {
    counter-increment: count 75;
  }
  80% {
    counter-increment: count 81;
  }
  90% {
    counter-increment: count 93;
  }
  100% {
    counter-increment: count 100;
  }
}

.loading-bar {
  animation-name: fadeoutLoading;
  animation-duration: 1s;
  animation-delay: 3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadeoutLoading {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.bubbleSlideOut {
  animation-name: bubbleSlideOut;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes bubbleSlideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}

.bubbleSlideIn {
  animation-name: bubbleSlideIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes bubbleSlideIn {
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(0);
  }
}
