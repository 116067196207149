.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #000f1d;
  user-select: none;
}

.ani-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25vh;
}

.dot-container {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.animated-dot {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: white;
  opacity: 0;
}

.loader-text {
  margin-right: 3rem;
  color: white;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

@media (max-width: 1000px) {
  .loader-text {
    font-size: 1rem;
  }
}
