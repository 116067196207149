.container {
  position: absolute;
  top: 2.5vh;
  left: 35vw;
  height: 95vh;
  width: 30vw;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 16px 24px 0px #00000052;
}

.header {
  background-color: #006dfb;
  height: 50px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 8px 0px #00000052;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 700;
  font-size: 21px;
}

.header-icon {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 6px;
}

.body-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif !important;
  margin-top: 2rem;
  color: #727884;
}

.animation-container {
  width: 90%;
  margin-left: 5%;
}

@media (max-width: 1000px) {
  .container {
    width: 95vw;
    left: 2.5vw;
  }

  .animation-container {
    width: 95%;
    margin-left: 0;
  }
}

.slideDown {
  animation-name: slideDown;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100vh);
  }
}
