html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #020304;
}

.logo-container {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 20rem;
  user-select: none;
}

.logo-container img {
  width: 20rem;
}

.speecharea__container {
  position: absolute;
  bottom: 2.5rem;
  right: 0;
  top: 0;
  margin-left: 2.5vw;
  /* margin-right: 2.5vw; */
  margin-top: 2vh;
  width: 30vw;
  height: calc(100vh - 3.125rem - 3rem);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.position-first {
  left: 35vw;
  top: calc(2.5vh + 3.125rem);
  z-index: 1;
  height: calc(100vh - 5vh - 3.125rem - 8vh);
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 1000px) {
  .position-first {
    left: 2vw;
    width: 89vw !important;

    height: calc(100vh - 5vh - 72px - 5.5rem) !important;
  }

  .mobile-toggler__container {
    display: block !important;
  }

  .speecharea__container {
    width: 95vw;
    margin-left: 3.5vw;
    height: 75vh;
    bottom: unset;
    top: 4rem;
  }

  .slideLeft {
    font-size: 1.5rem;
  }
}

.mobile-toggler__container {
  display: none;
}

.mobile-toggler {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.mobile-toggler__btn {
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: white;
  background-color: #006fff;
  font-family: "Source Sans Pro", sans-serif !important;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0.0625em solid #006fff;
  margin-right: 1.2rem;
}

.mobile-toggler__btn:hover {
  border: none;
}

.mobile-toggler__btn:active {
  border: #44a5ff;
}

.display-none {
  display: none !important;
}
