.next-btn__container {
  display: flex;
  justify-content: end;
}

.next-btn {
  height: 2rem;
  min-width: 6rem;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background: #ffffff;
  border: 0.0625em solid #eff0f2;
  box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
    0px 4px 8px rgba(96, 97, 112, 0.16);
  border-radius: 0.375rem;
  font-family: "Source Sans Pro", sans-serif !important;
}

.next-btn:hover {
  border: none;
}

.next-btn:active {
  border: #006fff;
}

.bubble__container {
  display: flex;
  flex-direction: row;
}

.bubble {
  width: 92%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  opacity: 0;
  border: 0.5px solid #00000010;
  box-shadow: 0px 20px 32px 0px #00000052;
  box-shadow: 0px 2px 8px 0px #00000014;
}

.bubble__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.bubble__image {
  width: 100%;
  user-select: none;
}

.bubble__avatar {
  width: 2rem;
  border-radius: 50%;
}

.personname {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
}

.timestamp {
  font-family: "Source Sans 3", sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
}

.bubble__text {
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400;
  font-size: 0.9rem;
  margin-block-start: 0;
  user-select: none;
}

.bubble-customer {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px 15px 15px 3px;
}

.bubble-consultant {
  background-color: #006dfb;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px 15px 3px 15px;
}

.now-blue {
  color: #8cc8ff;
}

.now-grey {
  color: #c2c6cd;
}

.more-link {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .more--width {
  width: 7.25rem;
} */

.download-link {
  text-decoration: none;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.download-button {
  display: flex;
  flex-direction: row;
  align-items: end;
  font-family: "Source Sans Pro", sans-serif !important;
  font-weight: 400;
}

.cta-label {
  margin-left: 0.5rem;
  margin-block-end: 0;
  margin-block-start: 0;
}

.margin-left-1 {
  margin-left: 0.5rem;
}
